import { RouteParams } from '@app/types/routerTypes';
import { Action, ActionWithPromiseReturn } from '@app/types/actionTypes';
import { FeedTypes } from '@app/services/opta/constants/feedTypes';
import { PageError } from '@app/types/errorTypes';
import {
  MatchResult,
  LiveMatchResult,
  LocalizedGames,
  MatchComments,
  MatchLineups,
  MatchLiveAudio,
} from '@app/types/matchTypes';
import { LanguageType } from '@app/types/localizationTypes';
import {
  SetGames,
  SetLiveMatch,
  ResetMatchCenter,
  ResetLiveMatch,
  MatchActionTypes,
  SetError,
  SetMatchResults,
  SetMatchEvents,
  SetMatchLineups,
  SetGamesDownloadCompleted,
  SetMatchFeedsDownloadCompleted,
  SetMatchLiveAudio,
  ResetMatchLiveAudio, PauseMatchLiveAudio,
} from '@app/store/actionTypes/matchActionTypes';

import AppRoutes from '@app/constants/routesConstants';
import { reduxStore } from '@app/store';
import { AppLanguages } from '@app/constants/localizationConstants';
import { CurrentRouteParams, setCurrentRoute } from '@app/store/actions/currentRouteActions';
import { getTopLevelMenuItem, getSecondLevelMenuItem } from '@app/helpers/menuHelpers';
import {
  mapLiveMatchResults, mapMatchLineups, getGameByUrlSlug, getGameByGameOptaId,
} from '@app/helpers/matchHelpers';
import { sendRequestTyped } from '@app/services/opta/request';
import {
  OptaScheduleLiveResponse,
  OptaCommentsResponse,
  OptaLineupsResponse,
} from '@app/services/opta/types/responseTypes';
import { getStandings } from '@app/store/actions/seasonActions';
import { waitForConfigurationDownload } from '@app/store/actions/configurationActions';
import { getGames } from '@app/services/kentico/gamesService';
import { getMatchResultsData } from '@app/services/opta/matchService';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as Translations from '@app/locales';

export function waitForGamesDownload(): Promise<void> {
  const state = reduxStore.store.getState();

  if (state.matchCenter.isGamesDownloadCompleted) return Promise.resolve();

  return new Promise((resolve): void => {
    const unsubscribe = reduxStore.store.subscribe(() => {
      const state = reduxStore.store.getState();

      if (state.matchCenter.isGamesDownloadCompleted) {
        resolve();
        unsubscribe && unsubscribe();
      }
    });
  });
}

export const setMatchCenterError = (error: PageError): SetError => ({
  type: MatchActionTypes.SET_ERROR,
  payload: error,
});

export const resetLiveMatchResults = (gameId: string): ResetLiveMatch => ({
  type: MatchActionTypes.RESET_LIVE_MATCH,
  payload: gameId,
});

export const resetMatchCenter = (): ResetMatchCenter => ({
  type: MatchActionTypes.RESET_MATCH_CENTER,
  payload: null,
});

export const setLiveMatchResults = (payload: LiveMatchResult): SetLiveMatch => ({
  type: MatchActionTypes.SET_LIVE_MATCH,
  payload,
});

export const setMatchLiveAudio = (payload: MatchLiveAudio): SetMatchLiveAudio => ({
  type: MatchActionTypes.SET_LIVE_AUDIO,
  payload,
});

export const resetMatchLiveAudio = (): ResetMatchLiveAudio => ({
  type: MatchActionTypes.RESET_LIVE_AUDIO,
});

export const pauseMatchLiveAudio = (payload: boolean): PauseMatchLiveAudio => ({
  type: MatchActionTypes.PAUSE_LIVE_AUDIO,
  payload,
});

export const getLiveMatchResults = ({ gameId, competitionOptaId, seasonId }: MatchResult): Action => (
  async (dispatch): Promise<void> => {
    try {
      const liveData = await sendRequestTyped<OptaScheduleLiveResponse>({
        path: 'competition.php',
        params: {
          feedTime: new Date().getTime(),
          feed_type: FeedTypes.live,
          json: true,
          season_id: seasonId,
          competition: competitionOptaId,
        },
      });

      const liveMatchResult = mapLiveMatchResults({ liveData, seasonId, optaId: competitionOptaId })[gameId];

      dispatch(setLiveMatchResults(liveMatchResult));
    } catch (e) {
      console.error('Error on fetching Match Live data', e);
      // TODO: clarify with PO error scenario
    }
  }
);

const setGames = (payload: LocalizedGames): SetGames => ({ type: MatchActionTypes.SET_GAMES, payload });

const setGamesDownLoadCompleted = (): SetGamesDownloadCompleted => ({
  type: MatchActionTypes.SET_GAMES_DOWNLOAD_COMPLETED, payload: true,
});

const setMatchFeedsDownLoadCompleted = (): SetMatchFeedsDownloadCompleted => ({
  type: MatchActionTypes.SET_MATCH_FEEDS_DOWNLOAD_COMPLETED, payload: true,
});

type GetGamesData = (language: LanguageType) => ActionWithPromiseReturn;
const getGamesByLanguage: GetGamesData = (language) => (
  async (dispatch): Promise<void> => {
    const games = await getGames(language);
    const data: LocalizedGames = {};
    data[language] = games;

    dispatch(setGames(data));
  }
);

export const getLocalizedGames = (): Action => (
  async (dispatch, getState): Promise<void> => {
    await waitForConfigurationDownload();

    try {
      const areGamesLoaded = !!getState().matchCenter.games;

      !areGamesLoaded && await Promise.all(
        AppLanguages.map((language) => dispatch((getGamesByLanguage(language)))),
      ).finally(() => dispatch(setGamesDownLoadCompleted()));
    } catch (e) {
      console.error('Error on fetching Games data', e);
      dispatch(setMatchCenterError(PageError.Sorry));
    }
  }
);

const setMatchResults = (payload: MatchResult | null): SetMatchResults => ({
  type: MatchActionTypes.SET_MATCH_RESULTS, payload,
});

type GetMatchFeeds = (
  seasonId: string,
  optaId: string,
  gameOptaId: string,
  language?: LanguageType,
) => ActionWithPromiseReturn;

export const getMatchResults: GetMatchFeeds = (seasonId, optaId, gameOptaId, language = 'it') => (
  async (dispatch): Promise<void> => {
    try {
      const matchResult = (await getMatchResultsData({ seasonId, optaId, language }) ?? [])
        .find((result) => result.gameOptaId.replace('g', '') === gameOptaId.replace('g', '')) ?? null;

      dispatch(setMatchResults(matchResult));
    } catch (e) {
      console.error('Error on fetching Match Results data', e);
      dispatch(setMatchCenterError(PageError.Sorry));
    }
  }
);

const setMatchLineups = (payload: MatchLineups | null): SetMatchLineups => ({
  type: MatchActionTypes.SET_MATCH_LINEUPS, payload,
});

export const getMatchLineups: GetMatchFeeds = (seasonId, optaId, gameOptaId) => (
  async (dispatch): Promise<void> => {
    try {
      const lineupsData = await sendRequestTyped<OptaLineupsResponse>({
        params: {
          feed_type: FeedTypes.lineups,
          json: true,
          season_id: seasonId,
          game_id: gameOptaId,
          competition: optaId,
        },
      });
      dispatch(setMatchLineups(mapMatchLineups(lineupsData, gameOptaId)));
    } catch (e) {
      console.error('Error on fetching Match Lineups data', e);
      dispatch(setMatchLineups(null));
    }
  }
);

const setMatchEvents = (payload: MatchComments[]): SetMatchEvents => ({
  type: MatchActionTypes.SET_MATCH_EVENTS, payload,
});

export const getMatchEvents: GetMatchFeeds = (seasonId, optaId, gameOptaId, language) => (
  async (dispatch): Promise<void> => {
    try {
      const lang = language ? { language } : {};
      const eventsData = await sendRequestTyped<OptaCommentsResponse>({
        params: {
          feed_type: FeedTypes.events,
          json: true,
          season_id: seasonId,
          game_id: gameOptaId,
          competition: optaId,
          ...lang,
        },
      });
      const messagesData = eventsData?.Commentary?.message ?? [];
      const events = (Array.isArray(messagesData) ? messagesData : [messagesData]).map((message) => ({
        time: message?.['@attributes']?.time,
        type: (message?.['@attributes']?.type ?? '').toLowerCase().replace(/ /g, '_'),
        comment: message?.['@attributes']?.comment ?? '',
        playerId1: message?.['@attributes']?.['player_ref1'] ?? '',
        playerId2: message?.['@attributes']?.['player_ref2'] ?? '',
      }));

      dispatch(setMatchEvents(events));
    } catch (e) {
      console.error('Error on fetching Match Lineups data', e);
      dispatch(setMatchEvents([]));
    }
  }
);

export const getMatchFeeds = ({ thirdLevel = '', language }: RouteParams): Action => (
  async (dispatch, getState): Promise<void> => {
    await waitForConfigurationDownload();
    await waitForGamesDownload();

    try {
      const game = getGameByUrlSlug(getState(), language, thirdLevel);
      const { seasonId = '', optaId = '', gameOptaId = '' } = game ?? {};

      await dispatch(getMatchResults(seasonId, optaId, gameOptaId));
      await dispatch(getMatchLineups(seasonId, optaId, gameOptaId));
      await dispatch(getMatchEvents(seasonId, optaId, gameOptaId, language));
      await dispatch(getStandings({ seasonId, optaId, language }));

      dispatch(setMatchFeedsDownLoadCompleted());
    } catch (e) {
      console.error('Error on fetching Match Feeds data', e);
      dispatch(setMatchCenterError(PageError.Sorry));
    }
  }
);

type SetMatchCenterMultiLangUrl = (args: RouteParams) => Action;

export const setMatchCenterMultiLangUrl: SetMatchCenterMultiLangUrl = ({
  topLevel, secondLevel = '', thirdLevel = '', forthLevel = '', language,
}) => (
  async (dispatch, getState): Promise<void> => {
    await waitForConfigurationDownload();
    await waitForGamesDownload();

    const state = getState();
    const gameOptaId = getGameByUrlSlug(state, language, thirdLevel)?.gameOptaId ?? '';
    const tabKey = ((): string => {
      switch (forthLevel) {
        case Translations[language]?.['matchcenter.tabs.lineups'].toLowerCase(): return 'matchcenter.tabs.lineups';
        case Translations[language]?.['matchcenter.tabs.statistics'].toLowerCase(): return 'matchcenter.tabs.statistics';
        case Translations[language]?.['matchcenter.tabs.results'].toLowerCase(): return 'matchcenter.tabs.results';
        case Translations[language]?.['matchcenter.tabs.standings'].toLowerCase(): return 'matchcenter.tabs.standings';
        default: return '';
      }
    })();
    const topLevelMenu = getTopLevelMenuItem(state, topLevel, language);
    const secondLevelMenu = getSecondLevelMenuItem(state, topLevel, secondLevel, language);
    const params = AppLanguages.reduce((acc, locale) => {
      const tab = Translations[locale]?.[tabKey] as string;

      acc.topLevel[locale] = topLevelMenu?.[locale]?.data?.url ?? '';
      acc.secondLevel[locale] = secondLevelMenu?.[locale]?.data?.url ?? '';
      acc.thirdLevel[locale] = getGameByGameOptaId(state, locale, gameOptaId)?.urlSlug ?? '';
      acc.forthLevel[locale] = tab.toLowerCase();
      acc.fifthLevel[locale] = '';

      return acc;
    }, {
      topLevel: {}, secondLevel: {}, thirdLevel: {}, forthLevel: {}, fifthLevel: {},
    } as unknown as CurrentRouteParams);

    dispatch(setCurrentRoute({ pathId: AppRoutes.CommonPage.path, params }));
  }
);
