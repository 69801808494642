import { MenCompetitionsMap, OptaSDCompetitionsMap } from '@app/services/opta/constants/competitionConstants';
import { LanguageType } from '@app/types/localizationTypes';
import { KenticoLanguages } from '../../server/src/types/Enums';

export type MatchStatus = 'live' | 'finished' | 'upcoming';

export type MatchPeriod = MatchLivePeriods | MatchFinishedPeriods | MatchUpcomingPeriods;

export type MatchHalfPeriods = 'firsthalf'
  | 'secondhalf'
  | 'extrafirsthalf'
  | 'firstextrahalf'
  | 'extrasecondhalf'
  | 'secondextrahalf';
export type MatchHalfTimePeriods = 'halftime' | 'extrahalftime';
export type MatchFinishedPeriods = 'fulltime' | 'full' | 'played'| 'ft'| 'postmatch'| 'result';
export type MatchUpcomingPeriods = 'prematch'| 'fixture' | 'postponed' | 'abandoned' | 'cancelled';
export type MatchLivePeriods = 'live' | 'playing' | 'shootout' | MatchHalfTimePeriods | MatchHalfPeriods;

export interface MatchTeam {
  id: string;
  name: string;
  shortName: string;
  goals: number | string;
  logoUrl: string;
}

export interface MatchComments {
  time: string;
  type: string;
  comment: string;
  playerId1: string;
  playerId2: string;
}

export interface BaseMatchResult {
  gameId: string;
  competitionOptaId: string;
  seasonId: string;
  matchStatus: MatchStatus;
  teams: {
    first: MatchTeam;
    second: MatchTeam;
  };
}

export interface LiveMatchResult extends BaseMatchResult {
  matchTime: string;
  isHalfTime: boolean;
}

export interface LiveMatchResults {
  [gameId: string]: LiveMatchResult;
}

export interface MatchResult extends BaseMatchResult {
  gameOptaId: string;
  matchDay: string;
  matchDate: string;
  matchDateTimeConfirmed: boolean;
  matchTime?: string;
  isHalfTime?: boolean;
  ticketUrl: string;
}

export interface ScheduleResults {
  [matchDate: string]: MatchResult[];
}

export interface HomeMatch extends MatchResult {
  competitionId: MenCompetitionsMap | OptaSDCompetitionsMap;
}

export type Game = {
  competition: string;
  competitionId: string;
  optaId: string;
  seasonId: string;
  seasonName: string;
  gameOptaId: string;
  urlSlug: string;
  streamLanguage: KenticoLanguages;
  audioStreamUrl: string;
  additionalAudioStreamUrl: string;
}

export type LocalizedGames = Partial<Record<LanguageType, Game[]>> | null;

export enum MatchStatistics {
  STATS_POSSESSION_PERCENTAGE = 'possession_percentage',
  STATS_CORNERS_TAKEN = 'corner_taken',
  STATS_OFFSIDES = 'total_offside',
  STATS_YELLOW_CARDS = 'total_yel_card',
  STATS_RED_CARDS = 'total_red_card',
  STATS_TOTAL_SHOTS = 'total_scoring_att',
  STATS_SHOTS_ON_TARGET = 'ontarget_scoring_att',
  STATS_SHOTS_OF_TARGET = 'shot_off_target',
  STATS_FOULS = 'fk_foul_lost',
  STATS_BLOCKED_SHOTS = 'blocked_scoring_att',
  STATS_SUCCESFULL_PASSES = 'accurate_pass',
  STATS_TOTAL_PASSES = 'total_pass',
  STATS_DUELS_WON = 'duel_won',
  STATS_DUELS_LOST = 'duel_lost',
  STATS_PASSES_TOTAL = 'total_pass',
  STATS_CROSSES_TOTAL = 'total_cross',
  STATS_SUCCESFULL_CROSES = 'accurate_cross',
  STATS_INTERCEPTION = 'interception',
  STATS_CLEARANCE = 'total_clearance',
  STATS_TOTAL_TACKLE = 'total_tackle',
  STATS_WON_TACKLE = 'won_tackle',
  STATS_SHOTS_FROM_BOX = 'attempts_ibox',
  STATS_SHOTS_OUT_BOX = 'attempts_obox',

  STATS_FORMATION_USED = 'formation_used',

  STATS_ASSISTS = 'goal_assist',
  STATS_GOALS = 'goals',
  STATS_MINS_PLAYED = 'mins_played',
}

export type MatchLineupsTeamStat = {
  [value in MatchStatistics]: number;
}

export type MatchLineupsTeamGoal = {
  time: string | number;
  playerId: string;
}

export type MatchLineupsTeamPlayer = {
  firstName: string;
  lastName: string;
  knownName: string;
  name: string;
  playerId: string;
  position: string;
  number: string;
  start: boolean;
  place: number;
}

export type MatchLineupsTeam = {
  teamId: string;
  logoUrl: string;
  formation: string;
  stats: MatchLineupsTeamStat;
  goals: MatchLineupsTeamGoal[]
  players: MatchLineupsTeamPlayer[]
}

export interface MatchLineups {
  home: MatchLineupsTeam;
  away: MatchLineupsTeam;
}

export interface MatchLiveAudio {
  audioStreamUrl: string;
  additionalAudioStreamUrl: string;
  paused: boolean;
}
